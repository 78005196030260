
//NOT EDITABLE!!!
import Hero from "../../components/Hero"
import Footer from "../../components/Footer"

// EDITABLE
import background from "../../assets/img/background.jpg" //Background
import backgroundMobile from "../../assets/img/backgroundM.jpg" //BackgroundMobile
import logo from '../../assets/img/logo.png'; //Logo


function pageLayout( props ) {
    //EDITABLE
    const deskripsi = "Selamat datang kembali di"
    const title1 = "SIDIMAS MAN 1 YOGYAKARTA"
    const title2 = "Sistem Informasi Digital Madrasah Negeri 1 Yogyakarta"
    const slogan = "PRESTASI TIADA HENTI, CERDAS DAN ISLAMI  BERKELAS DUNIA"
    const copyright = "2022 SIDIMAS MAN 1 YOGYAKARTA - Didukung oleh"

    return (
        <div>
            <Hero 
                logo={logo}
                background={background}
                backgroundMobile={backgroundMobile}
                deskripsi={deskripsi}
                title1={title1}
                title2={title2}
                slogan={slogan}
                appName={props.appName}
            >
                { props.children }
            </Hero>
            <Footer
                copyright={copyright}
            />
        </div>
    );
}


export default pageLayout;