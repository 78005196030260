import React from 'react';

import PageLayout from '../../layouts/PageLayout';
import Login from '../../components/Login'

function index(props) {
    const appName = 'Login'

    return (<>        <div style={{position:'sticky', top: 0, zIndex: 99, backgroundColor: '#ffffe0', padding: 8, width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
        <a href="/skl/" >Informasi Pengumuman Kelulusan Siswa/i MAN 1 YOGYAKARTA Silahkan klik disini</a>
    </div>
        <PageLayout
            appName={appName}
        >
            <Login/>
        </PageLayout>
        </>

    );
}

export default index;